<!-- UserModal.vue -->
<template>
	<b-modal
		v-if="userData"
		v-model="modalVisible"
		no-close-on-esc
		no-close-on-backdrop
		hide-footer
		size="xl"
		:title="translate('verifying_order_id', { order_id: userData.id })"
		@close="$emit('close')">
		<div>
			<b-row>
				<b-col cols="8">
					<p>{{ translate('user_id') }} : {{ userData.attributes.user_id }}</p>
					<p>{{ translate('username') }} : {{ userData.attributes.username }}</p>
					<p>{{ translate('id_number') }} : {{ userData.attributes.id_number }}</p>
					<p>{{ translate('recipients_name') }} : {{ userData.attributes.ship_to }}</p>
				</b-col>
				<b-col cols="4">
					<b-button
						variant="success"
						block
						icon-class="fa fa-check"
						class="bg-success-alt"
						@click="approve">
						{{ translate('approve') }}
					</b-button>
					<b-button
						variant="success"
						block
						icon-class="fa fa-check"
						@click="askForUpdate">
						{{ translate('ask_for_update') }}
					</b-button>
					<b-button
						v-if="$can('personal_documents', 'reject')"
						variant="danger"
						block
						icon-class="fa fa-times"
						@click="reject">
						{{ translate('reject') }}
					</b-button>
				</b-col>
			</b-row>
			<img
				v-if="!loading && userImageFront"
				:src="userImageFront"
				width="50%"
				alt="User Image">
			<img
				v-if="!loading && userImageBack"
				:src="userImageBack"
				width="50%"
				alt="User Image">
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="!(userImageBack && userImageFront)" />
		</div>
	</b-modal>
</template>

<script>
import Order from '@/util/Order';
import OrderVerification from '@/mixins/OrderVerification';
import {
	OrderVerifications, Users,
	Grids, Tooltip,
} from '@/translations';

export default {
	name: 'DocumentVerificationModal',
	messages: [OrderVerifications, Users, Grids, Tooltip],
	mixins: [OrderVerification],
	props: {
		userData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			modalVisible: true,
			userImageFront: '',
			userImageBack: '',
			orderData: new Order(),
		};
	},
	computed: {
		loading() {
			return this.orderData.loading;
		},
	},
	mounted() {
		this.fetchUserImage('front');
		this.fetchUserImage('back');
	},
	methods: {
		approve() {
			this.modalVisible = false;
			this.approveOrderVerification(this.userData.id, {
				onFinally: () => {
					this.$emit('reload');
					this.$emit('close');
					this.modalVisible = false;
				},
				onCancel: () => {
					this.modalVisible = true;
					this.$emit('close');
				},
			}, true);
		},
		askForUpdate() {
			this.modalVisible = false;
			this.requestDocumentUpdate(this.userData.id, {
				onFinally: () => {
					this.$emit('reload');
					this.$emit('close');
					this.modalVisible = false;
				},
				onCancel: () => {
					this.modalVisible = true;
					this.$emit('close');
				},
			}, true);
		},
		reject() {
			this.modalVisible = false;
			this.rejectOrderVerification(this.userData.id, {
				onFinally: () => {
					this.$emit('reload');
					this.$emit('close');
					this.modalVisible = false;
				},
				onCancel: () => {
					this.modalVisible = true;
					this.$emit('close');
				},
			}, true);
		},
		fetchUserImage(side) {
			this.orderData.getDocument(this.userData.id, side).then(() => {
				const { response } = this.orderData.data;
				const url = window.URL.createObjectURL(response.data);

				if (side === 'back') {
					this.userImageBack = url;
				} else {
					this.userImageFront = url;
				}
			});
			// this.userImage = 'placeholder_image_url';
		},
	},
};
</script>
